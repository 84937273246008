<template>
    <ul class="project-list">
        <li class="project-item" v-for="(item,index) in projectList" :key="index" v-show="item.service_name">
            <div class="type-column">
                <h3>{{item.service_name}}</h3>
                <p>{{item.service_type_name}}</p>
            </div>
            <div class="order-colume">
                <h3>{{item.order_count|valueKbit}}</h3>
                <div class="order-bar">
                    <p class="count-rate">{{item.count_rate}}</p>
                    <p class="progress-bar">
                        <span :style="{width:item.count_rate}"></span>
                    </p>
                </div>
            </div>
            <div class="revenue-colume">
                <h3><span>￥</span>{{item.revenue_count|valueKbit}}</h3>
                <div class="revenue-bar">
                    <p class="count-rate">{{item.revenue_rate}}</p>
                    <p class="progress-bar">
                        <span :style="{width:item.revenue_rate}"></span>
                    </p>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
	export default {
		name:"project-list",
        props:['projectList'],
	}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import './assets/project-list.scss';
</style>
