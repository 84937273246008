import { render, staticRenderFns } from "./project-list.vue?vue&type=template&id=721c0704&scoped=true&"
import script from "./project-list.vue?vue&type=script&lang=js&"
export * from "./project-list.vue?vue&type=script&lang=js&"
import style0 from "./project-list.vue?vue&type=style&index=0&id=721c0704&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721c0704",
  null
  
)

export default component.exports